<template>
  <div id="wrap">
    <div class="head">
      <!-- <div class="mainmenubg"> -->
      <!-- <img class="mainmenubgimg" src="./assets/mainmenu-bg.jpg" alt="" /> -->
      <!-- </div> -->

      <div class="topline">
        <img class="toplineimg" src="./assets/topline.jpg" alt="" />
      </div>

      <div class="psncontainer">
        <div class="head_wrap">
          <div class="logo">
            <router-link to="/" class="logo-router"
              ><img src="./assets/just-logo.png" />
            </router-link>
          </div>
          <div class="psnmenu_wrap" :class="isMenuActive ? 'active' : ''">
            <a class="btn_menu" href="#" @click="toggleMenu">MENU</a>
            <ul class="psnmenu" :class="display ? 'display' : 'nodisplay'">
              <li
                :class="isActiveOne || isClickedOne ? 'active' : ''"
                @mouseenter="mouseenter(1)"
                @mouseleave="mouseleave(1)"
                @click="click(1)"
              >
                <router-link class="psnrouter" ref="routerlink1" to="/">{{
                  menuLabels.home
                }}</router-link>
              </li>
              <li
                :class="isActiveTwo || isClickedTwo ? 'active' : ''"
                @mouseenter="mouseenter(2)"
                @mouseleave="mouseleave(2)"
                @click="click(2)"
              >
                <router-link
                  class="psnrouter"
                  ref="routerlink2"
                  to="/content"
                  >{{ menuLabels.content }}</router-link
                >
              </li>
              <li
                :class="isActiveThree || isClickedThree ? 'active' : ''"
                @mouseenter="mouseenter(3)"
                @mouseleave="mouseleave(3)"
                @click="click(3)"
              >
                <router-link
                  class="psnrouter"
                  ref="routerlink3"
                  to="/program"
                  >{{ menuLabels.program }}</router-link
                >
              </li>
              <li
                :class="isActiveFour || isClickedFour ? 'active' : ''"
                @mouseenter="mouseenter(4)"
                @mouseleave="mouseleave(4)"
                @click="click(4)"
              >
                <router-link
                  class="psnrouter"
                  ref="routerlink4"
                  to="/aboutus"
                  >{{ menuLabels.about }}</router-link
                >
              </li>
              <li
                :class="isActiveFive || isClickedFive ? 'active' : ''"
                @mouseenter="mouseenter(5)"
                @mouseleave="mouseleave(5)"
                @click="click(5)"
              >
                <router-link
                  class="psnrouter"
                  ref="routerlink5"
                  to="/contact"
                  >{{ menuLabels.contact }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <router-view />

    <div id="foot">
      <div class="foot_wrap">
        <div class="psnfootercontainer">
          <div class="footercontainer">
            <div class="footer1">
              <!-- <p>Спортын агшин бүрийг танд хүргэнэ!</p> -->
              <p>{{ footerItemSiteDescription }}</p>

              <div class="share_wrap">
                <ul class="list_pc_share">
                  <li v-for="social in footerItemSocial" :key="social">
                    <a :href="social.url" target="_blank">
                      <img :src="baseUrl + social.image" class="zoom-image" />
                    </a>

                    <!-- <a
                    style="background-image: "
                      :href= social.key === "url" ? social.value 
                      target="_blank"
                    ></a> -->
                  </li>
                </ul>

                <!-- <ul class="list_pc_share">
                  <li>
                    <a
                      class="facebook"
                      href="https://www.facebook.com/"
                      target="_blank"
                    ></a>
                  </li>
                  <li>
                    <a
                      class="insta"
                      href="https://www.instagram.com/"
                      target="_blank"
                    ></a>
                  </li>
                  <li>
                    <a
                      class="youtube"
                      href="https://www.youtube.com/@Premiersports.network"
                      target="_blank"
                    ></a>
                  </li>
                </ul> -->
              </div>
            </div>
            <div class="footer3">
              <div v-if="footerItemEmail" class="footer3row">
                <img src="./assets/email.png" alt="Image" class="emailimage" />
                <p>
                  <a :href="'mailto:' + footerItemEmail">{{
                    footerItemEmail
                  }}</a>
                </p>
              </div>
              <div v-if="footerItemPhone" class="footer3row">
                <img src="./assets/phone.png" alt="Image" class="phoneimage" />
                <a
                  style="color: white; padding: 0px 0px"
                  :href="phoneNumberHref"
                  >{{ footerItemPhone }}</a
                >
              </div>
              <div class="footer3row">
                <p style="cursor: pointer" @click="changeLanguage()">
                  {{ language === "mn" ? "English" : "Монгол хэл" }}
                </p>
              </div>
              <!-- <h1>+976 77772050</h1> -->
            </div>
          </div>

          <p class="contact">
            <br />
            {{ footerItemCopyRight }}
            <!-- Copyright ⓒ 2024 PSN. All rights reserved. -->
          </p>
        </div>
      </div>

      <img class="bottomlineimg" src="./assets/topline.jpg" alt="" />
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { ref, watch, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import useLanguageStore from "./store/languagStore";
import translations from "./translations/translations";

export default {
  setup() {
    // const phoneNumber = ref("+976 77772050");
    const route = useRoute();

    const { language, setLanguage } = useLanguageStore();
    let showMenu = ref(false);
    let isActiveOne = ref(false);
    let isActiveTwo = ref(false);
    let isActiveThree = ref(false);
    let isActiveFour = ref(false);
    let isActiveFive = ref(false);

    let isClickedOne = ref(false);
    let isClickedTwo = ref(false);
    let isClickedThree = ref(false);
    let isClickedFour = ref(false);
    let isClickedFive = ref(false);

    let isMenuActive = ref(false);
    let display = ref(false);

    const footerItemSiteDescription = ref("");
    const footerItemEmail = ref("");
    const footerItemPhone = ref("");
    const footerItemCopyRight = ref("");
    const footerItemSocial = ref(null);
    const baseUrl = "/storage/";
    const changeLanguage = () => {
      if (language.value === "mn") {
        setLanguage("en");
      } else {
        setLanguage("mn");
      }
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          "/api/v1/pb/theme-options"
        );

        const result = await response.json();

        const parsedData = JSON.parse(result["theme-psn-social_links"]);

        const transformedData = Object.values(parsedData).map((subArray) => {
          return subArray.reduce((acc, item) => {
            acc[item.key] = item.value || ""; // Handle missing values
            return acc;
          }, {});
        });

        // const transformedData = jsonObject.map((subArray) => {
        //   return subArray.reduce((acc, item) => {
        //     acc[item.key] = item.value || ""; // Handle missing values (like 'icon' in some cases)
        //     return acc;
        //   }, {});
        // });

        footerItemSiteDescription.value = result["theme-psn-site_description"];
        footerItemEmail.value = result["theme-psn-contact_email"];
        footerItemPhone.value = result["theme-psn-address"];
        footerItemCopyRight.value = result["theme-psn-copyright"];
        footerItemSocial.value = transformedData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(() => {
      fetchData();
    });

    const phoneNumberHref = computed(() => {
      // Remove any characters that are not digits, +, or -
      const sanitizedNumber = footerItemPhone.value.replace(/[^+\d]/g, "");
      return `tel:${sanitizedNumber}`;
    });

    const menuLabels = computed(() => {
      return translations[language.value];
    });

    //   router-link-exact-active
    const toggleMenu = () => {
      if (isMenuActive.value == false) {
        console.log("SCROLL");
      }
      isMenuActive.value = !isMenuActive.value;
      display.value = !display.value;
    };

    const click = (item) => {
      display.value = false;
      switch (item) {
        case 1:
          router.push("/");
          break;
        case 2:
          router.push("/content");
          break;
        case 3:
          router.push("/program");
          break;
        case 4:
          router.push("/aboutus");
          break;
        case 5:
          router.push("/contact");
          break;
        default:
          console.log("laeving!!!");
      }
    };

    watch(
      () => route,
      (to) => {
        // You can perform any actions here based on route changes
        switch (to.fullPath) {
          case "/":
            isClickedOne.value = true;
            isClickedTwo.value = false;
            isClickedThree.value = false;
            isClickedFour.value = false;
            isClickedFive.value = false;
            break;
          case "/content":
            isClickedOne.value = false;
            isClickedTwo.value = true;
            isClickedThree.value = false;
            isClickedFour.value = false;
            isClickedFive.value = false;
            break;
          case "/program":
            isClickedOne.value = false;
            isClickedTwo.value = false;
            isClickedThree.value = true;
            isClickedFour.value = false;
            isClickedFive.value = false;
            break;
          case "/aboutus":
            isClickedOne.value = false;
            isClickedTwo.value = false;
            isClickedThree.value = false;
            isClickedFour.value = true;
            isClickedFive.value = false;
            break;
          case "/contact":
            isClickedOne.value = false;
            isClickedTwo.value = false;
            isClickedThree.value = false;
            isClickedFour.value = false;
            isClickedFive.value = true;
            break;
          default:
            console.log("clicking!!!");
        }
      },
      { deep: true, immediate: true }
    );

    const mouseenter = (item) => {
      switch (item) {
        case 1:
          isActiveOne.value = true;
          break;
        case 2:
          isActiveTwo.value = true;
          break;
        case 3:
          isActiveThree.value = true;
          break;
        case 4:
          isActiveFour.value = true;
          break;
        case 5:
          isActiveFive.value = true;
          break;
        default:
          console.log("laeving!!!");
      }
    };
    const mouseleave = (item) => {
      switch (item) {
        case 1:
          isActiveOne.value = false;
          break;
        case 2:
          isActiveTwo.value = false;
          break;
        case 3:
          isActiveThree.value = false;
          break;
        case 4:
          isActiveFour.value = false;
          break;
        case 5:
          isActiveFive.value = false;
          break;
        default:
          console.log("hovering!!!");
      }
    };

    return {
      baseUrl,
      footerItemSocial,
      footerItemSiteDescription,
      footerItemEmail,
      footerItemPhone,
      footerItemCopyRight,
      phoneNumberHref,
      formattedPhoneNumber: footerItemPhone,
      showMenu,
      isActiveOne,
      isActiveTwo,
      isActiveThree,
      isActiveFour,
      isActiveFive,
      isClickedOne,
      isClickedTwo,
      isClickedThree,
      isClickedFour,
      isClickedFive,
      isMenuActive,
      display,
      toggleMenu,
      click,
      mouseenter,
      mouseleave,
      language,
      changeLanguage,
      menuLabels,
    };
  },
};
</script>

<style>
.emailimage {
  max-width: 50px; /* Adjust the size as needed */
  margin-right: 20px; /* Space between image and text */
}

.phoneimage {
  max-width: 50px; /* Adjust the size as needed */
  margin-right: 10px; /* Space between image and text */
}

* {
  box-sizing: border-box;
  outline: none;
  word-break: break-all;
  word-wrap: break-word;
  word-break: initial;
}

.footercontainer {
  display: flex;
  flex-direction: row;
  color: white;
}

.footer1 {
  font-family: TTCommonsItalic;
  font-size: 24px;
  margin: 0px;
  flex: 1;
  text-align: left;
}

.footer3row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end; /* Align content of the row to the right */
  margin-bottom: 20px; /* Space between rows */
}

.footer3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align rows to the right */
  padding: 0px;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  font-size: 24px;
  font-family: TTCommonsItalic;
  margin: 0px;
  flex: 1;
  /* text-align: right; */
}

#wrap {
  min-width: 1024px;
  height: 100%;
}

img {
  vertical-align: top;
  border: 0;
}

.head {
  z-index: 2 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100px; */
  background-size: cover;
  background-image: url("./assets/mainmenu-bg.jpg");
  /* background: #003bc4; */
}

.mainmenubg {
  position: fixed;
  top: 0;
  left: 0;
}

/* .mainmenubgimg {
  width: 100%;
  height: 100px;
} */

.toplineimg {
  width: 100%;
  height: 6px;
  /* height: auto; */
}

.bottomlineimg {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
}

.topline {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.psncontainer {
  padding: 0px 60px;
  position: relative;
  width: 960px;
  margin: 0 auto;
}

.head_wrap {
  height: 100px;
  line-height: 100px;
}

.head_wrap .logo {
  z-index: 1;
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: auto;
  background-size: cover;
  /* text-indent: -999px; */
  overflow: hidden;
  vertical-align: middle;
}

.head_wrap .psnmenu_wrap {
  position: absolute;
  top: 0;
  right: 0;
}

.psnmenu_wrap .psnmenu li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.psnmenu_wrap .psnmenu li .psnrouter {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  font-weight: 700;
  vertical-align: middle;
}

.psnmenu_wrap .psnmenu li + li {
  margin-left: 60px;
}

.head_wrap .btn_menu {
  display: none;
}

.psnmenu_wrap {
  font-size: 0;
}

a {
  color: #000;
  text-decoration: none;
}

#foot {
  position: relative;
  /* background: #003bc4; */
  /* background: #fff; */
  background-size: cover;
  /* background-size: contain; */
  background-image: url("./assets/footer-bg.jpg");
}

.foot_wrap {
  padding: 10px 0;
  text-align: center;
}

.foot_wrap .share_wrap .list_pc_share {
  font-size: 0;
  text-align: left;

  margin-top: 20px;
  margin-left: 0px;
}

.foot_wrap .share_wrap .list_pc_share li + li {
  margin-left: 12px;
}

.foot_wrap .share_wrap .list_pc_share li {
  display: inline-block;
  vertical-align: top;
}

.foot_wrap .share_wrap .list_pc_share li a.facebook {
  background-image: url("./assets/icons8-facebook-96.png");
}

.foot_wrap .share_wrap .list_pc_share li a.insta {
  background-image: url("./assets/icons8-instagram-96.png");
}

.foot_wrap .share_wrap .list_pc_share li a.youtube {
  background-image: url("./assets/icons8-youtube-logo-96.png");
}

.foot_wrap .share_wrap .list_pc_share li a {
  display: block;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.foot_wrap a {
  color: #fff;
}

.foot_wrap .contact {
  color: white;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 40px;
  font-family: TTCommonsThinItalic;
}

.footercontact {
  margin: 20px;
  display: flex;
  flex-direction: row;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: TTCommons;
  background: url("./assets/background-pattern.jpg");
  background-repeat: repeat;
  /* overflow: hidden; */
}

img {
  vertical-align: top;
  border: 0;
}

.navigation {
  color: #000;
  text-decoration: none;
}

ul {
  list-style: none;
}

.psnfootercontainer {
  width: 100%;
  padding: 0 100px;
}
/* 
@font-face {
  font-family: TTCommonsItalic;
  src: url(./assets/TTCommonsProCond-It.ttf);
}

@font-face {
  font-family: TTCommonsThinItalic;
  src: url(./assets/TTCommonsProCond-ThinIt.ttf);
}

@font-face {
  font-family: TTCommons;
  src: url(./assets/TTCommonsProExp-Rg.ttf);
}

@font-face {
  font-family: TTCommonsBold;
  src: url(./assets/TTCommonsPro-XBlk.ttf);
}

OpenSans*/
@font-face {
  font-family: TTCommonsItalic;
  src: url(./assets/fonts/OpenSans/OpenSans-Italic.ttf);
}

@font-face {
  font-family: TTCommonsThinItalic;
  src: url(./assets/fonts/OpenSans/OpenSans-LightItalic.ttf);
}

@font-face {
  font-family: TTCommons;
  src: url(./assets/fonts/OpenSans/OpenSans-Regular.ttf);
}

@font-face {
  font-family: TTCommonsBold;
  src: url(./assets/fonts/OpenSans/OpenSans-Bold.ttf);
}

@media (max-width: 1023px) {
  .footer3 a {
    font-size: 14px;
  }

  .emailimage {
    max-width: 20px; /* Adjust the size as needed */
    margin-right: 20px; /* Space between image and text */
  }

  .phoneimage {
    max-width: 20px; /* Adjust the size as needed */
    margin-right: 10px; /* Space between image and text */
  }

  .footer3row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center; /* Align content of the row to the right */
    margin-bottom: 0px; /* Space between rows */
  }

  .psnfootercontainer {
    padding: 0 10px;
  }

  .foot_wrap .share_wrap .list_pc_share li + li {
    margin-left: 24px;
  }

  #foot p {
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
  }

  #wrap {
    min-width: 320px;
  }

  .head_wrap {
    height: 80px;
    line-height: 80px;
  }

  .mainmenubgimg {
    width: 100%;
    height: 60px;
  }

  .psncontainer {
    width: 100%;
    padding: 0 10px;
  }

  .footercontainer {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
  }

  .foot_wrap .share_wrap .list_pc_share {
    font-size: 0;
    text-align: center;
    /* margin-left: -40px; */
  }

  .footer1 {
    flex: 1;
    text-align: center;
  }

  .footer3 {
    flex: 1;
    text-align: center;
  }

  .head_wrap .psnmenu_wrap {
    width: 100%;
    text-align: right;
  }

  .head_wrap .psnmenu_wrap {
    text-align: right;
  }

  .head_wrap .btn_menu {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 13px;
    background: url(./assets/btn_menu.png) no-repeat center center;
    background-size: auto;
    background-size: 22px;
    vertical-align: middle;
  }

  .head_wrap .psnmenu_wrap .psnmenu {
    display: none;
    position: relative;
    z-index: 1000 !important;
    text-align: center;
  }

  .head_wrap .psnmenu_wrap .psnmenu.display {
    display: block;
    /* margin-left: -40px; */
  }

  .head_wrap .psnmenu_wrap .psnmenu.nodisplay {
    display: none;
  }

  .head_wrap .psnmenu_wrap .psnmenu li {
    display: block;
    margin: 0;
    line-height: 70px;
    cursor: pointer;
  }

  .head_wrap .psnmenu_wrap .psnmenu li .psnrouter {
    z-index: 1000 !important;
    color: #fff;
    font-size: 15px;
  }

  .psnmenu {
    background: #003bc4;
    opacity: 0.8;
    height: 100vh;
  }

  .head_wrap .psnmenu_wrap .mask {
    display: none;
    z-index: 1 !important;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #003bc4;
    opacity: 0.8;
  }

  .head_wrap .psnmenu_wrap .psnmenu li:hover .psnrouter {
    color: #000;
  }

  .head_wrap .psnmenu_wrap .psnmenu li:hover {
    background: #fff;
  }

  .foot_wrap {
    padding: 50px 0px;
  }

  .foot_wrap a {
    color: white;
  }
}

@media (min-width: 1024px) {
  .psnmenu_wrap .psnmenu li.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    background: #fff;
  }
  .psnmenu {
    background: transparent;
    height: auto;
  }
}

ul,
ol {
  list-style: none;
}
</style>
