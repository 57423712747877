// languageStore.js
import { reactive, computed } from "vue";

// Load language from local storage or set default
const state = reactive({
  language: localStorage.getItem("language") || "mn",
});

const setLanguage = (lang) => {
  state.language = lang;
  localStorage.setItem("language", lang);
};

export default function useLanguageStore() {
  return {
    language: computed(() => state.language),
    setLanguage,
  };
}
