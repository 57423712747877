// src/translations.js
export default {
  en: {
    home: "HOME",
    about: "ABOUT US",
    contact: "CONTACT US",
    content: "CONTENTS",
    program: "PROGRAM",
    contents: "CONTENTS",
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  },
  mn: {
    home: "ЭХЛЭЛ",
    about: "БИДНИЙ ТУХАЙ",
    contact: "ХАМТРАН АЖИЛЛАХ",
    content: "КОНТЕНТУУД",
    program: "ХӨТӨЛБӨР",
    contents: "КОНТЕНТУУД",
    Mon: "Даваа",
    Tue: "Мягмар",
    Wed: "Лхагва",
    Thu: "Пүрэв",
    Fri: "Баасан",
    Sat: "Бямба",
    Sun: "Ням",
  },
};
