import { useHead } from '@unhead/vue'

export function usePageMeta({ title, description, ogImage, ogUrl }) {
  useHead({
    title,
    meta: [
      { name: 'description', content: !description && 'Спортын агшин бүрийг танд хүргэнэ!' },
      { property: 'og:title', content: !title && 'Premier Sports Network' },
      { property: 'og:description', content: !description && 'Спортын агшин бүрийг танд хүргэнэ!' },
      { property: 'og:image', content: !ogImage && 'https://beta.psn.mn/default.jpg' },
      { property: 'og:url', content: !ogUrl && 'https://beta.psn.mn' },
      { property: 'og:type', content: 'website' }
    ]
  })
}