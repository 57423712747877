<template>
  <div style="margin-top: 110px">
    <div v-if="data">
      <masonry-wall
        :items="data.data"
        :ssr-columns="1"
        :column-width="400"
        :gap="16"
      >
        <template #default="{ item }">
          <div class="image-container">
            <div v-if="item.image">
              <img :src="baseUrl + item.image.replace(/(\.[\w\d_-]+)$/i, '-468xauto$1')" class="zoom-image" />
            </div>
          </div>
        </template>
      </masonry-wall>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { usePageMeta } from "@/composables/usePageMeta";
export default {
  name: "ContentPage",
  setup() {

    usePageMeta({
      title: 'Контентууд | Premier Sports Network',
    });

    const data = ref(null);
    const baseUrl = "/storage/"; // Base URL for images

    const fetchData = async () => {
      try {
        const response = await fetch("/api/v1/pb/contents");
        const result = await response.json();
        data.value = result;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      data,
      baseUrl,
    };
  },
};
</script>

<style scoped>
.image-container {
  overflow: hidden;
  height: 100; /* Adjust as needed */
  position: relative;
}
.zoom-image {
  transition: transform 0.5s ease;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container:hover .zoom-image {
  transform: scale(1.1); /* Adjust scale as needed */
  transform-origin: center;
}
</style>
