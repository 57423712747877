<template>
  <div
    class="psncontainer"
    style="
      margin-top: 120px;
      margin-bottom: 100px;
      align-items: center;
      text-align: center;
    "
  >
    <div v-if="news">
      <p class="newsdetailtitle">{{ news.name }}</p>
      <img height="500px" :src="baseUrl + news.image" alt="" />
      <!-- <p class="newsdetaidesc">{{ news.content }}</p> -->
      <div class="newsdetaidesc" v-html="news.content"></div>
      <!-- Detail page content goes here -->
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
// const jsonData = require("@/assets/news.json");
import { ref, onMounted, watch } from "vue";
import useLanguageStore from "../store/languagStore";

export default {
  setup() {
    const { language } = useLanguageStore();

    const route = useRoute();
    const newsId = route.params.id;
    const news = ref(null);

    // const news = ref(null);
    const baseUrl = "/storage/"; // Base URL for images

    const fetchData = async (language) => {
      try {
        // const response = await fetch("/api/v1/pb/home");

        const response = await fetch(
          language === "mn"
            ? "/api/v1/pb/home"
            : "/api/v1/pb/home?lang=en_US"
        );

        const result = await response.json();
        news.value = result["news-featured"].filter(
          (item) => item.id.toString() == newsId.toString()
        )[0];
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(() => {
      fetchData(language.value);
    });

    watch(language, () => {
      fetchData(language.value);
    });

    return { newsId, news, baseUrl };
  },
};
</script>

<style scoped>
iframe[src*="youtube"] {
  width: 100% !important;
}
.newsdetailtitle {
  color: #003bc4;
  font-size: 24px;
  font-weight: 500;
  padding: 10px;
  font-family: TTCommonsBold;
  margin: 0px 0px 10px 0px;
}
.newsdetaidesc {
  white-space: pre-line;
  text-align: justify;
  padding: 60px 5px;
  font-size: 16px;
}

@media (min-width: 500px) and (max-width: 1023px) {
  .newsdetaidesc {
    text-align: justify;
    padding: 5;
    font-size: 12px;
    font-weight: 500;
  }
}

@media (max-width: 499px) {
  .newsdetaidesc {
    text-align: justify;
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
