<template>
  <div id="top">
    <Carousel :autoplay="autoplayValue" :wrapAround="wrapAroundValue">
      <Slide v-for="slide in sliders" :key="slide">
        <div class="carousel__item">
          <picture>
            <source
              :srcset="
                baseUrl + slide.img.replace(/(\.[\w\d_-]+)$/i, '-585x296$1')
              "
              media="(max-width: 1024px)"
            />
            <img :src="baseUrl + slide.img" />
          </picture>
        </div>
      </Slide>

      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>

  <div v-if="news" class="newcontainer">
    <div class="r-row">
      <div v-for="(item, i) in news" :key="i">
        <div v-if="item" @click="goToDetailPage(item.id)" class="r-column">
          <img
            class="newsimage"
            :src="baseUrl + item.image.replace(/(\.[\w\d_-]+)$/i, '-472x240$1')"
            alt=""
          />
          <div class="newstext">
            <p class="newstitle">
              {{ item.name }}
            </p>
            <p class="newsdesc">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="
      display: block;
      margin: 30px 0px;
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto;
      /* overflow-y: auto; */
    "
  >
    <p
      style="
        font-family: TTCommons;
        text-align: center;
        font-size: 36px;
        color: #003bc4;
        line-height: 30px;
        font-weight: 1200;
        padding: 0px 20px;
        font-family: TTCommonsBold;
      "
    >
      <strong>{{ translation.contents }}</strong>
    </p>
  </div>

  <Splide style="margin: 50px 0px" :options="options" :extensions="extensions">
    <SplideSlide v-for="slide in competitions" :key="slide">
      <div class="item">
        <img
          :src="baseUrl + slide.img"
          height="100%"
          width="100%"
          alt="Logo 1"
        />
      </div>
    </SplideSlide>
  </Splide>
  <div style="margin: 80px"></div>
</template>

<script>
import { ref, onMounted, defineComponent, computed, watch } from "vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
// const jsonData = require("@/assets/news.json");
import { usePageMeta } from "@/composables/usePageMeta";
import translations from "../translations/translations";
import useLanguageStore from "../store/languagStore";

export default defineComponent({
  name: "HomePage",

  setup() {
    const { language } = useLanguageStore();

    usePageMeta({
      title: "Premier Sports Network",
    });

    const verticalImage = ref(null);
    const news = ref(null);
    const sliders = ref(null);
    const competitions = ref(null);
    const baseUrl = "/storage/"; // Base URL for images

    const fetchData = async (language) => {
      try {
        // const response = await fetch("/api/v1/pb/home");

        const response = await fetch(
          language === "mn"
            ? "/api/v1/pb/home"
            : "/api/v1/pb/home?lang=en_US"
        );

        const result = await response.json();
        news.value = result["news-featured"];

        verticalImage.value =
          result["news-featured"][0].vertical_image === ""
            ? result["news-featured"][0].image
            : result["news-featured"][0].vertical_image;

        sliders.value = result.slider.images;
        competitions.value = result.competitions.images;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(() => {
      fetchData(language.value);
    });

    watch(language, () => {
      fetchData(language.value);
    });

    const translation = computed(() => {
      return translations[language.value];
    });

    return {
      verticalImage,
      news,
      baseUrl,
      sliders,
      competitions,
      translation,
    };
  },

  data() {
    const options = {
      type: "loop",
      drag: "free",
      focus: "center",
      autoWidth: true,
      pagination: false,
      arrows: false,
      gap: 100,
      autoScroll: {
        speed: 2,
      },
    };

    return {
      autoplayValue: 3000,
      wrapAroundValue: true,
      smallImage: require("@/assets/news1small.jpg"),
      // news: jsonData,
      options: options,
      extensions: {
        AutoScroll,
      },
    };
  },
  components: {
    Splide,
    SplideSlide,
    Carousel,
    Slide,
    Pagination,
  },
  methods: {
    goToDetailPage(id) {
      // this.$router.push("/news/" + id);
      window.open(this.$router.resolve("/news/" + id).href, "_blank");
    },
  },
});
</script>

<style>
/* Container for flexboxes */
.r-row {
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
}

/* Create four equal columns */
.r-column {
  -webkit-box-shadow: 1px 1px 1px 1px rgba(204, 204, 204, 1);
  -moz-box-shadow: 1px 1px 1px 1px rgba(204, 204, 204, 1);
  box-shadow: 1px 1px 1px 1px rgba(204, 204, 204, 1);
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  flex: 25%;
  cursor: pointer;
}

/* On screens that are 992px wide or less, go from four columns to two columns */
@media screen and (max-width: 992px) {
  .r-column {
    flex: 50%;
  }
}

/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .r-row {
    flex-direction: column;
  }
}

.newcontainer {
  margin: 10px auto;
  max-width: 1850px;
  padding: 0px 10px 10px 10px;
}

.carousel__item {
  min-height: 200px;
  /* height: auto; */
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  /* border-radius: 16px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  /* padding: 10px; */
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  /* border: 5px solid white; */
}

.newsdesc {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  padding: 0px 10px;
  width: auto;
  max-width: 440px;
}

.newstitle {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  padding: 0px 10px;
  font-family: TTCommonsBold;
}

.newsimage {
  left: 0;
  top: 0;
  width: auto;
  max-width: 440px;
  border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
}

.item {
  width: 180px;
  height: 180px;
  transition: width 1s ease;
}

.newstext {
  color: #000;
  background: #fff;
  padding: 10px;
  height: 100px;
  width: auto;
  max-width: 440px;
  border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
}

.newscontainer {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.newsitem1 {
  cursor: pointer;
  position: relative;
  width: 600px;
  margin: 10px;
  flex: 1;
}

.newsitemempty {
  height: 240px;
  position: relative;
}

.newsitemother {
  cursor: pointer;
  position: relative;
}

.newsitem2 {
  flex: 1;
}

.content-carousel-container {
  margin: 100px 0px;
  width: 100%;
  overflow: hidden;
}

.content-carousel {
  display: flex;
  width: 200%;
  animation: slide 15s linear infinite;
}

.content_logo {
  flex: 0 0 auto;
  padding: 10px;
}

.content-carousel img {
  height: 150px;
  padding: 0px 60px;
}

#top {
  margin-top: 100px;
}

@media (min-width: 1023px) and (max-width: 1366px) {
  /* .newsimage {
    width: 315px;
  }
  .newstext {
    width: 315px;
  }
  .newsdesc {
    width: 315px;
  } */
}

@media (min-width: 500px) and (max-width: 1023px) {
  p {
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
  }

  .newstitle {
    font-size: 16px;
    font-weight: 500;
  }

  .newsitemempty {
    height: 10px;
    position: relative;
  }

  #top {
    margin-top: 80px;
  }

  .content-carousel {
    animation: slide 5s linear infinite;
  }
}

@media (max-width: 499px) {
  p {
    font-size: 14px;
    line-height: 30px;
    font-weight: 500;
  }
  #top {
    margin-top: 80px;
  }

  .content-carousel {
    animation: slide 5s linear infinite;
  }

  .newsimage {
    max-width: 370px;
  }

  .newstext {
    max-width: 370px;
  }

  .newsdesc {
    max-width: 370px;
  }

  .r-column {
    max-width: 370px;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
} */
</style>
