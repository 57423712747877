<template>
  <div
    style="
      display: flex;
      margin-top: 110px;
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
      overflow-y: auto;
    "
  >
    <!-- <div style="margin-top: 60px; padding: 10px" class="epgcontainer"> -->
    <!-- :class="{ selectedTv: isActive === item }" -->
    <div
      style="
        width: 100%;
        text-align: center;
        display: inline-block;
        font-size: 16px;
        margin: 12px;
        font-weight: 600;
        cursor: pointer;
      "
      @click="setTv(item)"
      v-for="item in tvs"
      :key="item"
      :style="{ opacity: isActive == item ? 1 : 0.5 }"
    >
      <!-- <p class="programmTab"> -->
      <img
        :src="require(`../assets/${item}.png`)"
        height="auto"
        width="100px"
      />
      <!-- </p> -->
    </div>
  </div>
  <!-- </div> -->
  <div
    style="
      display: flex;
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
      overflow-y: auto;
    "
  >
    <div
      :class="{ selectedDay: isDayActive === day.day }"
      style="
        width: 100%;
        text-align: center;
        display: inline-block;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        margin: 4px;
      "
      @click="setDay(day.day)"
      v-for="day in days"
      :key="day"
    >
      <p class="programmTab">
        {{ translation[day.day] }}
      </p>
    </div>
  </div>
  <div
    style="
      display: flex;
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
      overflow-y: auto;
      margin-top: 40px;
    "
  >
    <ul>
      <li v-for="item in programs" :key="item.id">
        <p class="programItem">{{ item.description }} - {{ item.name }}</p>
      </li>
    </ul>
  </div>
  <div style="height: 500px"></div>
</template>

<script>
import axios from "axios";
import { ref, onMounted, computed } from "vue";
import { usePageMeta } from "@/composables/usePageMeta";
import useLanguageStore from "../store/languagStore";
import translations from "../translations/translations";

export default {
  name: "ProgramPage",
  setup() {
    usePageMeta({
      title: "Хөтөлбөр | Premier Sports Network",
    });

    const { language } = useLanguageStore();

    const isActive = ref(null);
    const isDayActive = ref(null);
    const programs = ref([]);
    const programsWeek = ref([]);
    const tvs = ref(["PSN1", "PSN2", "PSN3", "PSN4", "PSN5"]);
    const days = ref([]); // Initially, no image is selected

    // const tvImgSrc = computed(() => {
    //   return isActive.value ? 'active-image.jpg' : 'default-image.jpg';
    // });

    const translation = computed(() => {
      return translations[language.value];
    });

    const setTv = async (tv) => {
      // console.log("tv: " + tv);
      isActive.value = tv;

      console.log(isActive.value);

      if (tv == "PSN1") {
        await fetchData("CH1149");
        await setDay("Mon");
      } else if (tv == "PSN2") {
        await fetchData("CH1214");
        await setDay("Mon");
      } else if (tv == "PSN3") {
        await fetchData("CH1074");
        await setDay("Mon");
      } else if (tv == "PSN4") {
        await fetchData("CH1224");
        await setDay("Mon");
      } else if (tv == "PSN5") {
        await fetchData("CH1225");
        await setDay("Mon");
      } else {
        await fetchData("CH1149");
        await setDay("Mon");
      }
    };

    const setDay = async (day) => {
      // console.log("day: " + day);
      isDayActive.value = day;
      const currentDay = days.value.filter((item) => item.day == day);
      await getCurrentDayProgram(currentDay[0]);
    };

    async function getCurrentDayProgram(day) {
      programs.value = programsWeek.value.filter(
        (item) =>
          item.startDate >= day.startEpoch && item.startDate <= day.endEpoch
      );
    }

    const fetchData = async (channelId) => {
      const { startEpoch, endEpoch } = getStartAndEndOfWeek();
      // console.log("Start of the week (epoch):", startEpoch);
      // console.log("End of the week (epoch):", endEpoch);
      // console.log(" fetchData PSN 1");
      // CH1149 CH1214 CH1074 CH1224 CH1225
      try {
        const response = await axios.get(
          `https://epg.psn.mn/${channelId}/${startEpoch}/${endEpoch}`
        );
        programsWeek.value = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    function getStartAndEndOfWeek() {
      const now = new Date();

      // Start of the week (Sunday)
      const startOfWeek = new Date(now);
      startOfWeek.setHours(0, 0, 0, 0);
      const day = now.getDay();
      const diff = (day === 0 ? -6 : 1) - day; // Subtract day to get Monday (0 is Sunday, so -6 to go back to Monday)
      startOfWeek.setDate(now.getDate() + diff);

      // End of the week (Saturday)
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      endOfWeek.setHours(23, 59, 59, 999);

      const startEpoch = startOfWeek.getTime() + 1;
      const endEpoch = endOfWeek.getTime();

      return {
        startEpoch,
        endEpoch,
      };
    }

    async function getStartAndEndOfWeekDays() {
      const now = new Date();

      // Start of the week (Monday)
      const startOfWeek = new Date(now);
      startOfWeek.setHours(0, 0, 0, 0);

      // Adjust to the start of the week (Monday)
      const day = now.getDay();
      const diff = (day === 0 ? -6 : 1) - day; // Subtract day to get Monday (0 is Sunday, so -6 to go back to Monday)
      startOfWeek.setDate(now.getDate() + diff);

      const weekDays = [];

      for (let i = 0; i < 7; i++) {
        // Calculate the start of the day
        const startOfDay = new Date(startOfWeek);
        startOfDay.setDate(startOfWeek.getDate() + i);
        startOfDay.setHours(0, 0, 0, 0);

        // Calculate the end of the day
        const endOfDay = new Date(startOfDay);
        endOfDay.setHours(23, 59, 59, 999);

        const startEpoch = startOfDay.getTime();
        const endEpoch = endOfDay.getTime();

        const en = startOfDay.toDateString().substring(0, 3);

        let mn = "Даваа";

        if (en == "Mon") {
          mn = "Даваа";
        } else if (en == "Tue") {
          mn = "Мягмар";
        } else if (en == "Wed") {
          mn = "Лхагва";
        } else if (en == "Thu") {
          mn = "Пүрэв";
        } else if (en == "Fri") {
          mn = "Баасан";
        } else if (en == "Sat") {
          mn = "Бямба";
        } else if (en == "Sun") {
          mn = "Ням";
        }

        weekDays.push({
          day: en,
          startEpoch,
          endEpoch,
          mn,
        });
      }

      return weekDays;
    }

    onMounted(async () => {
      days.value = await getStartAndEndOfWeekDays();
      await fetchData("CH1149");
      isActive.value = "PSN1";
      await setDay("Mon");
    });

    return {
      programs,
      tvs,
      days,
      isActive,
      isDayActive,
      setTv,
      setDay,
      translation,
    };
  },
};
</script>

<style scoped>
@media (min-width: 500px) and (max-width: 1023px) {
  li {
    padding-left: 10px;
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
  }
}

@media (max-width: 499px) {
  li {
    padding-left: 10px;
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
  }
}

.programmTab {
  color: #003bc4;
}

li {
  padding-left: 20px;
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
}

.selectedTv {
  /* background-color: yellow; Define your custom selected item style here */
  opacity: 1;
  border: 2px solid #003bc4;
  border-radius: 5px;
}

.selectedDay {
  /* background-color: yellow; Define your custom selected item style here */
  border: 2px solid #003bc4;
  border-radius: 5px;
}

.tabcontainer {
  display: flex;
}
.epgcontainer {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap; /* Ensures items stay in a single line */
}
</style>
