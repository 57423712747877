<template>
  <div style="margin-top: 100px">
    <div class="contactuscontainer" v-if="item">
      <div class="left-column">
        <img :src="baseUrl + item.image" />
      </div>
      <div class="right-column" v-html="item.content"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { usePageMeta } from "@/composables/usePageMeta";
import useLanguageStore from "../store/languagStore";
export default {
  setup() {
    usePageMeta({
      title: "Хамтран ажиллах | Premier Sports Network",
    });

    const { language } = useLanguageStore();

    const item = ref(null);
    const baseUrl = "/storage/"; // Base URL for images

    const fetchData = async (language) => {
      try {
        const response = await fetch(
          language === "mn"
            ? "/api/v1/pb/page?id=9"
            : "/api/v1/pb/page?id=9&lang=en_US"
        );

        const result = await response.json();

        item.value = result;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(() => {
      fetchData(language.value);
    });

    watch(language, () => {
      fetchData(language.value);
    });

    return {
      item,
      baseUrl,
    };
  },
};
</script>

<style>
.horizontal-line {
  border-top: 2px solid #003bc4; /* Adjust color and thickness as needed */
  margin: 20px 0; /* Optional: Adjust margin to control spacing */
}

.aboutustitlebig {
  font-family: TTCommonsBold;
  font-size: 24px;
  color: #003bc4;
  line-height: 30px;
  font-weight: 1200;
  padding: 5px 0px;
}

.aboutustitle {
  font-family: TTCommonsBold;
  font-size: 14px;
  color: #003bc4;
  line-height: 30px;
  font-weight: 700;
  margin: 0px 0px;
}

.aboutusdesc {
  font-family: TTCommons;
  font-size: 14px;
  color: #003bc4;
  line-height: 30px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 10px;
}

.contactuscontainer {
  /* min-height: calc(100vh - 100px); */
  height: 100%;
  display: flex;
}

.left-column {
  /* width: 50%; */
  /* height: calc(100% - 100px); */
  height: 100%;
  /* flex: 0 0 auto; */
  margin-right: 10px;
}

.left-column img {
  width: auto;
  /* max-width: 100%; */
  height: calc(100vh - 100px);
  min-height: 613px;
  /* height: calc(100% - 100px); */
}

.right-column {
  margin: 20px 50px 0px 50px;
  flex: 1; /* Allow the right column to grow */
  min-height: 400px; /* Specify the minimum height of the right column */
}

@media (min-height: 600px) and (max-height: 1450px) {
}

@media (min-width: 600px) and (max-width: 1450px) {
  .aboutustitlebig {
    font-family: TTCommonsBold;
    font-size: 16px;
    color: #003bc4;
    line-height: 30px;
    font-weight: 1200;
    padding: 5px 0px;
  }

  .aboutustitle {
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
  }

  .aboutusdesc {
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
  }

  .left-column {
    display: none;
  }
  .right-column {
    padding: 10px;
    width: 100%;
    height: 100%;
    margin: 0px 100px;
    /* overflow: auto; */
  }
}

@media (max-width: 599px) {
  .aboutustitlebig {
    font-family: TTCommonsBold;
    font-size: 16px;
    color: #003bc4;
    line-height: 30px;
    font-weight: 1200;
    padding: 5px 0px;
  }

  .aboutustitle {
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
  }

  .aboutusdesc {
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
  }
  .left-column {
    display: none;
  }
  .right-column {
    padding: 10px;
    width: 100%;
    height: 100%;
    margin: 0px 40px;
    /* overflow: auto; */
  }
}
</style>
