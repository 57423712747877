import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import ContentPage from "../views/ContentPage.vue";
import ProgramPage from "../views/ProgramPage.vue";
import AboutUsPage from "../views/AboutUsPage.vue";
import ContactUsPage from "../views/ContactUsPage.vue";
import NewsPage from "../views/NewsPage.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/content",
    name: "ContentPage",
    component: ContentPage,
  },
  {
    path: "/program",
    name: "ProgramPage",
    component: ProgramPage,
  },
  {
    path: "/aboutus",
    name: "AboutUsPage",
    component: AboutUsPage,
  },
  {
    path: "/contact",
    name: "ContactUsPage",
    component: ContactUsPage,
  },
  { path: "/news/:id", component: NewsPage },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
