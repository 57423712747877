<template>
  <div style="margin-top: 100px">
    <div class="contactuscontainer" v-if="item">
      <div class="left-column">
        <img :src="baseUrl + item.image" />
      </div>
      <div class="right-column" v-html="item.content"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { usePageMeta } from "@/composables/usePageMeta";
import useLanguageStore from "../store/languagStore";

export default {
  setup() {
    usePageMeta({
      title: "Бидний тухай | Premier Sports Network",
    });

    const { language } = useLanguageStore();

    const item = ref(null);
    const baseUrl = "/storage/"; // Base URL for images

    const fetchData = async (language) => {
      try {
        const response = await fetch(
          language === "mn"
            ? "/api/v1/pb/page?id=8"
            : "/api/v1/pb/page?id=8&lang=en_US"
        );

        const result = await response.json();

        item.value = result;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(() => {
      fetchData(language.value);
    });

    watch(language, () => {
      fetchData(language.value);
    });

    return {
      item,
      baseUrl,
    };
  },
};
</script>

<style></style>
